// @import '../../node_modules/bootstrap/scss/bootstrap';
$icon-font-path: '../../node_modules/bootstrap-sass/assets/fonts/bootstrap/';

// @import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/functions";
@import 'variables';
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";

// @import "../../node_modules/bootstrap/scss/code";

@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/custom-forms";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";

// @import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";

@import "../../node_modules/bootstrap/scss/badge";

@import "../../node_modules/bootstrap/scss/jumbotron";

@import "../../node_modules/bootstrap/scss/alert";

@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/media";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";

@import "../../node_modules/bootstrap/scss/utilities";
// @import "../../node_modules/bootstrap/scss/print";

@import "../../node_modules/angular-calendar/css/angular-calendar.css";

// Google places autocomplete
.pac-container {
    position: absolute !important;
    z-index: 999999;
}

// MATERIAL DESIGN ICONS
.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
    position: relative;
    top: 3px;
}

.amo-button-holder {
    display: none;
}
.show-chat {
    .amo-button-holder {
        display: block;
    }
}

@import '@angular/cdk/overlay-prebuilt.css';
